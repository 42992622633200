/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { checkIsActive } from '../../../../_helpers'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LanguageIcon from '@material-ui/icons/Language'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import SpeakerIcon from '@material-ui/icons/Speaker'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import DescriptionIcon from '@material-ui/icons/Description';

export function AsideMenuList({ layoutProps }) {
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	let userMenu
	userMenu = <>
							<li className="menu-section">
								<h4 className="menu-text">CONTINGUT</h4>
								<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/videos', false)} ${getMenuItemActive('/edit-video', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/videos">
									<span className="menu-icon">
										<OndemandVideoIcon/>
									</span>
									<span className="menu-text">Videos</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/podcasts', false)} ${getMenuItemActive('/edit-podcast', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/podcasts">
									<span className="menu-icon">
										<SpeakerIcon/>
									</span>
									<span className="menu-text">Podcasts</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/thehubs', false)} ${getMenuItemActive('/edit-thehub', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/thehubs">
									<span className="menu-icon">
										<DeviceHubIcon/>
									</span>
									<span className="menu-text">The Hub</span>
								</NavLink>
							</li>
							<li className="menu-section">
								<h4 className="menu-text">USUARIS</h4>
								<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/admins', false)} ${getMenuItemActive('/edit-admin', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/admins">
									<span className="menu-icon">
										<AssignmentIndIcon/>
									</span>
									<span className="menu-text">Administradors/es</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/receivers', false)} ${getMenuItemActive('/edit-receiver', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/receivers">
									<span className="menu-icon">
										<ContactMailIcon/>
									</span>
									<span className="menu-text">Destinataris newsletters</span>
								</NavLink>
							</li>
							<li className="menu-section">
								<h4 className="menu-text">ALTRES</h4>
								<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/tags', false)} ${getMenuItemActive('/edit-tag', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/tags">
									<span className="menu-icon">
										<LocalOfferIcon/>
									</span>
									<span className="menu-text">Etiquetes</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/languages', false)} ${getMenuItemActive('/edit-language', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/languages">
									<span className="menu-icon">
										<LanguageIcon/>
									</span>
									<span className="menu-text">Idiomes</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/texts', false)} ${getMenuItemActive('/edit-text', false)}`}
								aria-haspopup="true"
							>
								<NavLink className="menu-link" to="/texts">
									<span className="menu-icon">
										<DescriptionIcon/>
									</span>
									<span className="menu-text">Texts</span>
								</NavLink>
							</li>
						</>

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{userMenu}


			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
