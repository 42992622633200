import { API, authClient } from '../../utils/index'
import { uploadSingleFile } from "../files"

// Get all podcasts
export const getPodcasts = () => {
  return authClient().get(`${API}/podcast?tags=true`)
}

// Get podcast by id
export const getPodcastById = (id) => {
  return authClient().get(`${API}/podcast/${id}`)
}

// Delete podcast
export const deletePodcast = (id) => {
  return authClient().delete(`${API}/podcast/${id}`)
}

// Create podcast
export const postPodcast = async (podcast, image, imagevert) => {
  if (image) {
    const fileURL = await uploadSingleFile(image, 'podcasts')
    podcast.image = fileURL.data.fileURL
  }
  if (imagevert) {
    const fileURL = await uploadSingleFile(imagevert, 'podcasts')
    podcast.imagevert = fileURL.data.fileURL
  }
  return authClient().post(`${API}/podcast`, podcast)
}

// Update podcast
export const updatePodcast = async (id, podcast, image, imagevert) => {
    if (image) {
      const response = await uploadSingleFile(image, 'podcasts')
      podcast.image = response.data.fileURL
    }
    if (imagevert) {
      const response = await uploadSingleFile(imagevert, 'podcasts')
      podcast.imagevert = response.data.fileURL
    }
    return authClient().put(`${API}/podcast/${id}`, podcast)
}

// Change order podcast
export const changeOrderPodcast = async (id, order) => {
  return authClient().put(`${API}/podcast/change-order/${id}?order=${order}`)
}