import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import AdminsPage from './modules/ContentManager/admins/AdminsPage'
import EditAdminsPage from './modules/ContentManager/admins/EditAdminsPage'
import ReceiversPage from './modules/ContentManager/receivers/ReceiversPage'
import EditReceiversPage from './modules/ContentManager/receivers/EditReceiversPage'
import TagsPage from './modules/ContentManager/tags/TagsPage'
import EditTagsPage from './modules/ContentManager/tags/EditTagsPage'
import CategoriesPage from './modules/ContentManager/categories/CategoriesPage'
import EditCategoriesPage from './modules/ContentManager/categories/EditCategoriesPage'
import LanguagesPage from './modules/ContentManager/languages/LanguagesPage'
import EditLanguagesPage from './modules/ContentManager/languages/EditLanguagesPage'
import PodcastsPage from './modules/ContentManager/podcasts/PodcastsPage'
import EditPodcastsPage from './modules/ContentManager/podcasts/EditPodcastsPage'
import VideosPage from './modules/ContentManager/videos/VideosPage'
import EditVideosPage from './modules/ContentManager/videos/EditVideosPage'
import ThehubsPage from './modules/ContentManager/thehub/ThehubsPage'
import EditThehubsPage from './modules/ContentManager/thehub/EditThehubsPage'
import TextsPage from './modules/ContentManager/texts/TextsPage'
import EditTextsPage from './modules/ContentManager/texts/EditTextsPage'

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */ }
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />
			{/* Audios */}
			<ContentRoute from="/podcasts" component={PodcastsPage} />
			<ContentRoute from="/edit-podcast/:id?" component={EditPodcastsPage} />
			{/* Videos */}
			<ContentRoute from="/videos" component={VideosPage} />
			<ContentRoute from="/edit-video/:id?" component={EditVideosPage} />
			{/* Thehubs */}
			<ContentRoute from="/thehubs" component={ThehubsPage} />
			<ContentRoute from="/edit-thehub/:id?" component={EditThehubsPage} />
			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
			{/* Receivers */}
			<ContentRoute from="/receivers" component={ReceiversPage} />
			<ContentRoute from="/edit-receiver/:id?" component={EditReceiversPage} />
			{/* Tags */}
			<ContentRoute from="/tags" component={TagsPage} />
			<ContentRoute from="/edit-tag/:id?" component={EditTagsPage} />
			{/* Categories */}
			<ContentRoute from="/categories" component={CategoriesPage} />
			<ContentRoute from="/edit-category/:id?" component={EditCategoriesPage} />
			{/* Languages */}
			<ContentRoute from="/languages" component={LanguagesPage} />
			<ContentRoute from="/edit-language/:id?" component={EditLanguagesPage} />
			{/* Texts */}
			<ContentRoute from="/texts" component={TextsPage} />
			<ContentRoute from="/edit-text/:id" component={EditTextsPage} />
			<Redirect to="/error" />
		</Switch>
	)
}
