import { /* baseClient, */ authClient, API } from '../../utils/index'

// Get all tags
export const getTags = () => {
  return authClient().get(`${API}/tag`)
}

// Get tag by id
export const getTagById = (id) => {
  return authClient().get(`${API}/tag/${id}`)
}

// Delete tag
export const deleteTag = (id) => {
  return authClient().delete(`${API}/tag/${id}`)
}

// Create tag
export const postTag = (tag) => {
  return authClient().post(`${API}/tag`, tag)
}

// Update tag
export const updateTag = (id, tag) => {
  return authClient().put(`${API}/tag/${id}`, tag)
}
