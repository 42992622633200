import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteCategory, getCategoryById, postCategory, updateCategory } from '../../../../api/category'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyCategory() {
  return {
    fullName: {}
  }
}

export default function EditCategoriesPage() {
  const [category, setCategory] = useState(getEmptyCategory())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const categoryId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!categoryId) {
      disableLoadingData()
      return
    }
    getCategoryById(categoryId).then(res => {
      if (res.status === 200) {
        setCategory(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get category.' })
      history.push('/categories')
    })
  }, [categoryId, disableLoadingData, history])

  function saveCategory() {
    if (!categoryId) {
      postCategory(category).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Category successfully created.' })
          history.push('/categories')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save category.' })
      })
    } else {
      updateCategory(categoryId, category).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/categories')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!category[element]) category[element] = {}
      let newText = category[element]
      newText[lang] = event.target.value
      setCategory({ ...category, [element]: newText })
    } else setCategory({ ...category, [element]: event.target.value })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`fullName`}
          label="Full name"
          value={category.fullName[lang] || ''}
          onChange={handleChange('fullName', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit category'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/categories')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveCategory()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save category
      </Button>
      {categoryId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete category
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this category?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteCategory(categoryId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Category deleted successfully' })
                history.push('/categories')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete economic sector.' })
            })
          }}
        />
      </>}
    </>
  );
}
