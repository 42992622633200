import { API, authClient } from '../../utils/index'
import { uploadSingleFile } from "../files"

// Get all videos
export const getVideos = () => {
  return authClient().get(`${API}/video?tags=true`)
}

// Get video by id
export const getVideoById = (id) => {
  return authClient().get(`${API}/video/${id}`)
}

// Delete video
export const deleteVideo = (id) => {
  return authClient().delete(`${API}/video/${id}`)
}

// Create video
export const postVideo = async (video, image, imagevert) => {
  if (image) {
    const fileURL = await uploadSingleFile(image, 'videos')
    video.image = fileURL.data.fileURL
  }
  if (imagevert) {
    const fileURL = await uploadSingleFile(imagevert, 'videos')
    video.imagevert = fileURL.data.fileURL
  }
  return authClient().post(`${API}/video`, video)
}

// Update video
export const updateVideo = async (id, video, image, imagevert) => {
    if (image) {
      const response = await uploadSingleFile(image, 'videos')
      video.image = response.data.fileURL
    }
    if (imagevert) {
      const response = await uploadSingleFile(imagevert, 'videos')
      video.imagevert = response.data.fileURL
    }
    return authClient().put(`${API}/video/${id}`, video)
}

// Change order video
export const changeOrderVideo = async (id, order) => {
  return authClient().put(`${API}/video/change-order/${id}?order=${order}`)
}