import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteUser, getUserById, postUser, updateUser } from '../../../../api/user'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyReceiver() {
  return {
    email: '',
    role: 'receiver'
  }
}

export default function EditReceiversPage() {
  const [receiver, setReceiver] = useState(getEmptyReceiver())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const receiverId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!receiverId) {
      disableLoadingData()
      return
    }
    getUserById(receiverId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        setReceiver(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get receiver.' })
      history.push('/receivers')
    })
  }, [receiverId, disableLoadingData, history])

  function saveReceiver() {
    if (receiver.password !== receiver.repeatPassword) {
      alertError({ error: null, customMessage: 'Passwords do not match.' })
      return
    }
    delete receiver.repeatPassword
    if (!receiverId) {
      postUser(receiver).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Receiver successfully created.' })
          history.push('/receivers')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save receiver.' })
      })
    } else {
      updateUser(receiverId, receiver).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/receivers')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setReceiver({ ...receiver, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit receiver'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`email`}
            label="Email"
            value={receiver.email}
            onChange={handleChange('email')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/receivers')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveReceiver()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save receiver
      </Button>
      {receiverId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete receiver
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this receiver?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteUser(receiverId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Receiver deleted successfully' })
                history.push('/receivers')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete receiver.' })
            })
          }}
        />
      </>}
    </>
  );
}
