import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { Button } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { getThehubById, postThehub, updateThehub } from '../../../../api/thehub'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import Editor from '../../../components/editor/Editor'

function getEmptyThehub() {
  return {
    fullName: {}
  }
}

export default function EditThehubsPage() {
  const [thehub, setThehub] = useState(getEmptyThehub())
  const thehubId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!thehubId) {
      disableLoadingData()
      return
    }
    getThehubById(thehubId).then(res => {
      if (res.status === 200) {
        setThehub(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get thehub.' })
      history.push('/thehubs')
    })
  }, [thehubId, disableLoadingData, history])

  function saveThehub() {
    if (!thehubId) {
      postThehub(thehub).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Thehub successfully created.' })
          history.push('/thehubs')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save thehub.' })
      })
    } else {
      updateThehub(thehubId, thehub).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/thehubs')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!thehub[element]) thehub[element] = {}
      let newText = thehub[element]
      newText[lang] = event.target.value
      setThehub({ ...thehub, [element]: newText })
    } else setThehub({ ...thehub, [element]: event.target.value })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
				<Editor
				body={(thehub.fullName && thehub.fullName[lang]) || ''}
				setBody={_value => handleChange('fullName', lang)({target: {value: _value}})}
				className='max-height'
				/>
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit thehub'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/thehubs')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveThehub()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save thehub
      </Button>
    </>
  );
}
