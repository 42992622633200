import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle, booleanFormatter } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getPodcasts, deletePodcast, changeOrderPodcast } from '../../../../api/podcast'
import { Button, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import { getTags } from '../../../../api/tag'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

function getData(podcasts, tags) {
  let data = []
  for (let i = 0; i < podcasts.length; ++i) {
      const elem = {}
      elem.order = podcasts[i].order
      elem.name = podcasts[i].fullName.es
      elem.createdAt = podcasts[i].createdAt
      elem.idpodcast = podcasts[i].idpodcast
      elem.active = podcasts[i].active
      elem.featured = podcasts[i].featured
      elem.tag = podcasts[i].tags/* .map(tag => tag.fullName.es).join('; ') */
      elem.id = podcasts[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function PodcastsPage() {
  const [data, setData] = useState([])
  const [podcastId, setPodcastId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function handleSort(item1, item2, order1, order2) {
    changeOrderPodcast(item1.id, order1).then(res => {
      if (res.status === 200) {
        changeOrderPodcast(item2.id, order2).then(resp => {
          if (resp.status === 200) {
            setRefresh(true)
          }
        })
      }
    })
  }

  function sortFormatter(cell) {
    if (!data.length) return <></>
    const index = data.findIndex(item => item.order === cell)
    const indexPrevious = data.findIndex(item => item.order === cell + 1)
    const indexNext = data.findIndex(item => item.order === cell - 1)
    return (<>
      {data[index].order === data.length - 1 ? <Button style={buttonsStyle} size="small" disabled={true}>{''}</Button> : <Tooltip title="Move up">
        <Button style={buttonsStyle} size="small"
          onClick={() => handleSort(data[index], data[indexPrevious], data[index].order + 1, data[index].order)}><ArrowUpwardIcon/></Button>
      </Tooltip>}
      {data[index].order === 0 ? <Button style={buttonsStyle} size="small" disabled={true}>{''}</Button> : <Tooltip title="Move down">
        <Button style={buttonsStyle} size="small"
          onClick={() => handleSort(data[index], data[indexNext], data[index].order - 1, data[index].order)}><ArrowDownwardIcon/></Button>
      </Tooltip>}
    </>)
  }

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-podcast/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setPodcastId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }


  const columns = [
    { dataField: 'order', text: '', formatter: sortFormatter },
    { dataField: 'name', text: 'Title' },
    { dataField: 'tag', text: 'Tags', formatter: cell => cell.map(elem =>
      <span className='badge badge-success mr-2 mt-2'>
				{elem.fullName.es}
			</span>
      )  },
    { dataField: 'active', text: 'Active', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'featured', text: 'Featured', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getPodcasts().then((res) => {
      if (res.status === 200) {
        getTags().then((respTAG) => {
          if (respTAG.status === 200) {
            setData(getData(res.data, respTAG.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get tags.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get podcasts.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Podcasts list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-podcast')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to remove this podcast?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deletePodcast(podcastId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'Podcast removed successfully.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not remove podcast.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
