import { /* baseClient, */ authClient, API } from '../../utils/index'
import { uploadSingleFile } from "../files"

export const login = (credentials) => {
  return authClient().post(`${API}/user/signinAdmin`, credentials)
}

export const register = (body) => {
  return authClient().post(`${API}/user/signup`, body);
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh`)
}

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user`)
}

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`)
}

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`)
}

// Validate user
export const validateUser = (id) => {
  return authClient().put(`${API}/user/validate/${id}`)
}

// Create user
export const postUser = async (user, file) => {
  if (file) {
    const fileURL = await uploadSingleFile(file, 'users')
    user.image = fileURL.data.fileURL
  }
  return authClient().post(`${API}/user`, user)
}

// Update user
export const updateUser = async (id, user, file) => {
  if (file) {
    const response = await uploadSingleFile(file, 'videos')
    user.image = response.data.fileURL
  }
  return authClient().put(`${API}/user/${id}`, user)
}
