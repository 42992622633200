import { /* baseClient, */ authClient, API } from '../../utils/index'

// Get all thehubs
export const getThehubs = () => {
  return authClient().get(`${API}/thehub`)
}

// Get thehub by id
export const getThehubById = (id) => {
  return authClient().get(`${API}/thehub/${id}`)
}

// Delete thehub
export const deleteThehub = (id) => {
  return authClient().delete(`${API}/thehub/${id}`)
}

// Create thehub
export const postThehub = (thehub) => {
  return authClient().post(`${API}/thehub`, thehub)
}

// Update thehub
export const updateThehub = (id, thehub) => {
  return authClient().put(`${API}/thehub/${id}`, thehub)
}
