import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteTag, getTagById, postTag, updateTag } from '../../../../api/tag'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyTag() {
  return {
    fullName: {}
  }
}

export default function EditTagsPage() {
  const [tag, setTag] = useState(getEmptyTag())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const tagId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!tagId) {
      disableLoadingData()
      return
    }
    getTagById(tagId).then(res => {
      if (res.status === 200) {
        setTag(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get tag.' })
      history.push('/tags')
    })
  }, [tagId, disableLoadingData, history])

  function saveTag() {
    if (!tagId) {
      postTag(tag).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Tag successfully created.' })
          history.push('/tags')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save tag.' })
      })
    } else {
      updateTag(tagId, tag).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/tags')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!tag[element]) tag[element] = {}
      let newText = tag[element]
      newText[lang] = event.target.value
      setTag({ ...tag, [element]: newText })
    } else setTag({ ...tag, [element]: event.target.value })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`fullName`}
          label="Full name"
          value={tag.fullName[lang] || ''}
          onChange={handleChange('fullName', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit tag'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/tags')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveTag()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save tag
      </Button>
      {tagId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete tag
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this tag?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteTag(tagId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Tag deleted successfully' })
                history.push('/tags')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete economic sector.' })
            })
          }}
        />
      </>}
    </>
  );
}
